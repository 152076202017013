<template>
    <div :class="`--${colorTheme}`" class="type-label">
        <Typography :variant="labelVariant" class="text"
            >{{
                $t(nameSanitized, 1, {
                    locale: pageLanguage,
                })
            }}
        </Typography>
    </div>
</template>

<script setup>
const props = defineProps({
    name: {
        type: String,
        default: 'other',
    },
    customLabel: {
        type: String,
        default: '',
    },
    variant: {
        type: String,
        default: 'small',
        // 'large' used on Detail Page Headings
        validator: (value) => {
            return ['small', 'large'].includes(value);
        },
    },
    pageLanguage: {
        type: String,
        default: 'en',
    },
});

const colorTheme = computed(() => {
    let color = 'grey';

    switch (props.name.toLowerCase()) {
        case 'event':
        case 'conference':
            color = 'purple';
            break;
        case 'commentary':
        case 'edition':
        case 'feature':
        case 'testimony':
        case 'post':
        case 'posts':
        case 'legacyinthemedia':
        case 'inthemedia':
        case 'in the media':
        case 'pressrelease':
        case 'questionanswer':
            color = 'blue';
            break;
        case 'report':
        case 'research':
        case 'book':
        case 'article':
        case 'paper': {
            color = 'teal';
            break;
        }
        case 'video':
        case 'videos':
        case 'podcast': {
            color = 'yellow';
            break;
        }
        case 'collection':
            color = 'tan';
            break;
        default:
            color = 'grey';
    }

    return color;
});

const nameSanitized = computed(() => {
    let name = '';

    if (props.customLabel) {
        name = props.customLabel;
    } else {
        switch (props.name.toLowerCase()) {
            case 'legacyinthemedia':
            case 'inthemedia':
                name = 'in the media';
                break;
            case 'pressrelease':
                name = 'press release';
                break;
            case 'post':
            case 'posts':
                name = 'commentary';
                break;
            case 'questionanswer':
                name = 'Q&A';
                break;
            default:
                name = props.name;
        }
    }

    return name;
});

const labelVariant = computed(() => {
    return `label-${props.variant}`;
});
</script>

<style lang="scss" scoped>
.type-label {
    text-transform: uppercase;
    border-radius: 0.2rem;
    display: inline-block;
    border: 0.1rem solid color(noir, default, 0.03);

    // &.small {
    //     padding: 0 0.8rem .2rem;
    // }
    // &.large {
    //     padding: 0.5rem 0.8rem 0.6rem;
    // }
}

.--purple {
    background-color: color(purple, light);
    color: color(purple);
}

.--teal {
    background-color: color(teal, light);
    color: color(teal);
}

.--blue {
    background-color: color(blue, light);
    color: color(blue);
}

.--yellow {
    background-color: color(yellow, light);
    color: color(yellow);
}

.--tan {
    background-color: color(tan, light);
    color: color(tan);
}

.--grey {
    background-color: color(grey);
    color: color(grey, dark);
}

.text {
    display: inline-block;
    padding: calc(1em * 5 / 10) calc(1em * 8 / 10);
    text-transform: uppercase;
}
</style>
